
.tierName{
    color: rgba(255,255,255,0.8);
}

.tierDiv:hover .tierName{
    transition: all 0.1s ease-in-out;
    color: #fff;
}

.tierDiv{
    background-color: #191a1d;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
     user-select: none; /* Standard syntax */
}

.actionBtn{
    background-color: #121316;
    color: #fff;
    border: solid 1px rgba(255,255,255,0.1);
    transition: all 0.1s ease-in-out;
}

.tierDiv:hover .actionBtn{
    color: #06BA63;
    background-color: #06BA63;
    color:#000
}

.actionBtn:hover {
    opacity: 0.8;
    color: white;
    cursor: pointer;
}

.actionBtn:active {
    opacity: 0.8;
    transition: all 0.05s ease;
    transform: translateY(2px);
}

/* // Initially opacity of mainDiv is 0 then it is changed to 1 when the component is mounted */
.mainDiv{
    opacity: 0;
    animation-name: fadeInOpacity;
    animation-duration: 0.3s;
    animation-timing-function: ease-in;
    opacity: 1;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}




/* The switch - the box around the slider */
.switch {
    font-size: 12px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #E4E4E7;
    border: 1px solid #adb5bd;
    transition: .4s;
    border-radius: 30px;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 1.4em;
    width: 1.4em;
    border-radius: 20px;
    left: 0.27em;
    bottom: 0.25em;
    background-color: #fff;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #007bff;
    border: 1px solid #007bff;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #007bff;
  }
  
  input:checked + .slider:before {
    transform: translateX(1.4em);
    background-color: #fff;
  }


  .titleDiv1{
    font-weight: 700;
    font-size:30px;
    line-height:40px;
    color: rgba(255,255,255);
}

@media screen and (max-width: 800px) {

    .titleDiv1{
        font-size: 26px;
        line-height: 36px;
    }
}